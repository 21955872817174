
import { defineComponent, reactive, ref, computed, inject } from 'vue'
import axios from '@/http'
import { useRoute, useRouter } from 'vue-router'
import { enumLotStates, localizeLotStates } from '@/constains'
import { ILot } from '@/interfaces'
import LotProductInfo from './LotProductInfo.vue'
import { useStore } from 'vuex'

export default defineComponent({
	name: 'Lot',
	props: {
		lotProp: {
			type: Object
		}
	},
	setup(props) {
		const store = useStore()
		const userRoleIds: number[] = store.state?.auth?.userRoleIds
		const checkRole = inject<((bool: boolean) => never) | undefined>('checkRole', undefined)
		const isUpdateLot = ref(!!Object.keys(props?.lotProp || {}).length)
		const lotDashboard = computed(() => {
			if (isUpdateLot.value) {
				return {
					created: new Date(props?.lotProp?.created).toLocaleString(),
					modified: new Date(props?.lotProp?.modified).toLocaleString(),
					state: props?.lotProp?.state
				}
			}
			return {
				created: '-',
				modified: '-',
				state: '-'
			}
		})
		const lot = reactive({
			name: props?.lotProp?.name ?? '',
			quantity: props?.lotProp?.quantity ?? '',
			rejected: props?.lotProp?.rejected ?? '',
			costPrice: props?.lotProp?.costPrice ?? ''
		})
		const route = useRoute()
		const router = useRouter()
		const isSaveUpdateLot = computed(() => {
			if (!isUpdateLot.value || !isSaveNewLot.value) return false
			return Object.keys(lot).some(key => String(lot[key as keyof typeof lot]) !== String((props.lotProp || {})[key]))
		})
		const isSaveNewLot = computed(() => {
			return Object.keys(lot).every(key => String(lot[key as keyof typeof lot]))
		})
		function validateInt(keyLot: keyof typeof lot) {
			if (!Number.isInteger(Number(lot[keyLot]))) lot[keyLot] = lot[keyLot].slice(0, -1)
			if (lot[keyLot] !== '') lot[keyLot] = Number(lot[keyLot])
		}
		function validateDecimal(keyLot: keyof typeof lot) {
			if (!Number(lot[keyLot])) lot[keyLot] = lot[keyLot].slice(0, -1)
		}
		function updatePropLot(newUpdateLot: ILot) {
			if (!props?.lotProp) return
			Object.keys(newUpdateLot).forEach(key => {
				;(props?.lotProp || {})[key] = newUpdateLot[key as keyof typeof newUpdateLot]
			})
		}
		function prepareSaveLot() {
			if (!checkRole) return
			const allowedRoleIds = [3, 9]
			const isUserCanChange = userRoleIds.some(id => allowedRoleIds.includes(id))
			if (!isUserCanChange) checkRole(false)
			Object.keys(lot).forEach(key => {
				if (key !== 'costPrice' && key !== 'name') lot[key as keyof typeof lot] = Number(lot[key as keyof typeof lot])
			})
			if (isUpdateLot.value) return updateLot(lot)
			return createLot(lot)
		}
		async function updateLot(lot: Omit<ILot, 'id' | 'modified' | 'state' | 'created'>) {
			try {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const [_id, [newUpdateLot]] = (await axios.put('/lot/update', { id: Number(route.params.id), data: lot })).data
				updatePropLot(newUpdateLot)
			} catch (e) {
				alert('Не удалось обновить лот')
			}
		}
		async function createLot(lot: Omit<ILot, 'id' | 'modified' | 'state' | 'created'>) {
			try {
				const createdLot: ILot = (await axios.post('/lot/create', lot)).data
				return router.push('/lot/' + createdLot.id)
			} catch (e) {
				alert('Не удалось создать лот')
			}
		}
		return {
			lot,
			isUpdateLot,
			isSaveUpdateLot,
			isSaveNewLot,
			lotDashboard,
			localizeLotStates,
			enumLotStates,
			validateDecimal,
			validateInt,
			prepareSaveLot
		}
	},
	components: { LotProductInfo }
})
