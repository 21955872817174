
import { defineComponent, ref } from 'vue'
import axios from '@/http'

type ILotInfo = null | {
	totalPrice: number
	totalProductCount: number
	totalUnitCount: number
}

export default defineComponent({
	props: {
		lotId: {
			type: String
		}
	},
	setup(props) {
		const lotInfo = ref<ILotInfo>(null)
		const loadLotInfo = ref<boolean>(false)

		async function getLotInfo() {
			loadLotInfo.value = true
			const res = await axios.get(`/product/find-product-by-lot-id/${props.lotId}`)
			lotInfo.value = res.data
			loadLotInfo.value = false
		}
		return { lotInfo, loadLotInfo, getLotInfo }
	}
})
